a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

* {
  border-spacing: 0;
}

.marker {
  z-index: 1;
}
.path {
  z-index: 2;
}
.info {
  z-index: 3;
}

/* 엘리베이터 애니메이트 시작 */
.elevator_box {
  display: flex;
  justify-content: space-around;
}
.elevator_l {
  position: relative;
  height: 500px;
  background-color: #000;
  background: url(/public/images/hallway.png) no-repeat center center;
  overflow: hidden;
}
.floor {
  position: absolute;
  left: 190px;
  top: 210px;
  display: block;
  font-size: 60px;
  color: #1f1f20;
}
.elevator {
  position: relative;
  width: 100%;
  height: 500px;
  background: url(/public/images/elevator.png) no-repeat center center;
  overflow: hidden;
}
.elevator_name {
  z-index: 10;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.elevator_name p:last-child {
  margin-top: -5px;
  font-size: 25px;
}
.door-left {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/leftdoor.png) no-repeat left top;
  animation: leftcloseDoor 3s ease-out forwards;
}
.door-right {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/rightdoor.png) no-repeat right top;
  animation: rightcloseDoor 3s ease-out forwards;
}
.opendoor-left {
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: url(/public/images/leftdoor.png) no-repeat left top;
  /* animation: leftopenDoor 3s ease-out forwards; */
}
.opendoor-right {
  position: absolute;
  top: 0;
  right: -50%;
  width: 50%;
  height: 100%;
  background: url(/public/images/rightdoor.png) no-repeat right top;
  /* animation: rightopenDoor 3s ease-out forwards; */
}
.opendoor-left-ani {
  position: absolute;
  top: 0;
  /* left: -50%; */
  width: 50%;
  height: 100%;
  background: url(/public/images/leftdoor.png) no-repeat left top;
  animation: leftopenDoor 3s ease-out forwards;
}
.closedoor-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/leftdoor.png) no-repeat left top;
  /* animation: leftopenDoor 3s ease-out forwards; */
}
.closedoor-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/rightdoor.png) no-repeat right top;
  /* animation: rightopenDoor 3s ease-out forwards; */
}
.door-movedown {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2700px;
  background: url(/public/images/elevator_up.png) no-repeat left top #000;
  animation: moveUp 3s ease 3s infinite normal;
  overflow: hidden;
}
.door-moveup {
  position: absolute;
  top: -370px;
  width: 100%;
  height: 2000px;
  background: url(/public/images/elevator_down.png) no-repeat left 170px #000;
  animation: moveDown 3s ease 3s infinite normal;
}
.door-stop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2700px;
  background: url(/public/images/elevator_up.png) no-repeat left top #000;
  overflow: hidden;
}
.door-stop-ani {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2700px;
  background: url(/public/images/elevator_up.png) no-repeat left top #000;
  animation: appear1 4s ease-in-out;
  overflow: hidden;
}
.door-left {
  left: 0;
}
.door-right {
  right: 0;
}
.elevator_dash {
  width: 100%;
  background-color: #fff;
  border: 2px solid #eee;
}
.elevator_l .status_light {
  display: flex;
  position: absolute;
  left: 160px;
  top: 39px;
}
.elevator_l .circle_red {
  display: inline-block;
  margin: 0;
  width: 13px;
  height: 13px;
  border-radius: 50px;
  background-color: red;
  vertical-align:baseline;
}
.elevator_l .elevator_num span {
  color:#fff;
  font-size: 18px;
}
.elevator_l .elevator_num .status {
  display: block;
  margin-top: 1px;
  margin-left: 5px;
  padding: 3px 15px;  
  border-radius: 80px;
  background-color: #0068b3;
  font-size: 10px;
  text-align: center;
}
.elevator_l .elevator_floor span {
  position: relative;
  left: 47px;
  top: -5px;
  color:#fff;
  font-size: 40px;
}
.status_light {
  display: flex;
  width: 100%;
  height: 100%;
}
.circle_red {
  display: inline-block;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: red;
  vertical-align: baseline;
}
.circle_blue {
  display: inline-block;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #0068b3;
  vertical-align: baseline;
}
.elevator_num {
  margin-left: 20px;
}
.elevator_num span {
  color:#fff;
  font-size: 28px;
}
.elevator_num .status {
  display: block;
  margin-top: 0;
  margin-left: 5px;
  padding: 1px 15px 3px 15px;  
  border-radius: 80px;
  background-color: #0068b3;
  font-size: 17px;
  text-align: center;
}
.elevator_num .status_stop {
  background-color: #707070 !important;
}
.elevator_num .comm_err {
  background-color:#707070 !important;
}
.status_light {
  position: relative;
}
.uparrow {
  position: absolute;
  left: -160px;
  top: -36px;
  width: 100%;
  height: 100%;
  background: url(/public/images/uparrow.png) no-repeat center center;
  animation: appear1 4s ease-in-out, Uparrow 3s ease-in-out 3s infinite normal;
}
.dash_uparrow {
  animation: appear1 4s ease-in-out, Uparrow 2s ease-in-out 2s infinite normal;
}
.downarrow {
  position: absolute;
  left: -160px;
  top: -45px;
  width: 100%;
  height: 100%;
  background: url(/public/images/downarrow.png) no-repeat center center;
  animation: appear1 4s ease-in-out, Downarrow 3s ease-in-out 3s infinite normal;
}
.dash_downarrow {
  animation: appear1 4s ease-in-out, Downarrow 2s ease-in-out 2s infinite normal;
}
.elevator_floor span {
  position: relative;
  left: 180px;
  top: 0;
  color:#fff;
  font-size: 55px;
}
.elevator_dash .elevator_floor span {
  color:#464646;
}
.elevator_dash .status_light {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.elevator_dash .elevator_num {
  margin-top: -13px;
  margin-left: 0;
}
.elevator_dash .elevator_num span {
  color:#464646;
}
.elevator_dash .elevator_num span.status {
  color: #fff;
}
.elevator_dash .elevator_icon span {
  color:#464646;
  font-size: 65px;
}
.elevator_dash .elevator_floor span {
  left: 0;
  padding: 0 5px;
  vertical-align: middle;
} 
.elevator_dash .up_icon span {
  color:#00d458;
}
.elevator_dash .down_icon span {
  color:red;
}
.door_icon span {
  margin: 0 -18px;
}

.closedoor-left-ani {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/leftdoor.png) no-repeat left top;
  animation: leftcloseDoor 3s ease-out forwards;
}
.closedoor-right-ani {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/rightdoor.png) no-repeat right top;
  animation: rightcloseDoor 3s ease-out forwards;
}
@keyframes leftcloseDoor {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rightcloseDoor {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes leftopenDoor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.opendoor-right-ani {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: url(/public/images/rightdoor.png) no-repeat right top;
  animation: rightopenDoor 3s ease-out forwards;
}

@keyframes rightopenDoor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}

@keyframes appear1 {
  0%, 40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveUp {
  0%, 50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-700px);
  }
}

@keyframes moveDown {
  0%, 50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(470px);
  }
}

@keyframes Uparrow {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes Downarrow {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@media (max-width: 1350px) {
  .elevator_num span {
    color: #fff;
    font-size: 20px;
  }
  .elevator_num .status {
    font-size: 15px;
  }
  .elevator_floor span {
    font-size: 45px;
  }
  .elevator_dash .elevator_icon span {
    font-size: 45px;
  }
  .responsive {
    width: 100%;
  }
  .circle_red {
    width: 10px;
    height: 10px;
    vertical-align: baseline;
  }
  .circle_blue {
    width: 10px;
    height: 10px;
    vertical-align: baseline;
  }
}

/* 엘리베이터 애니메이트 끝 */

/* 차트 CSS 시작 */
.barchart1 div:first-child, .barchart2 div:first-child{width: auto;}
.chart_tit {padding: 8px 08px 35px 8px;}
.chart_tit .title {padding:5px 8px;background-color: #767676;color:#fff;}
.chart_box {display: flex;justify-content: space-between; align-items: end;}
/* .chart_box div{display: flex;width:100%;background-color: #ddd;} */
/* .chart_box div p.c-blue{width:10%;background-color: #008FFB;}
.chart_box div p.c-mint{width:50%;background-color: #00E396;}
.chart_box div p.c-red{width:70%;background-color: #FEB01A;} */
.now_state {padding:20px 0;text-align: center;font-weight: bold;color:#0f4db3;font-size: 3rem;}
.leglegend_circle {
  font-size: 14px;
}
.leglegend_circle p {display: flex; align-items: center;}
.legend_circle_r {
  display: block;
  margin: 0 5px;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: red;
}
.legend_circle_o {
  display: block;
  margin: 0 5px;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: #FEB01A;
}
.legend_circle_g {
  display: block;
  margin: 0 5px;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: #1ADB16;
}
.chartbox .MuiBox-root h6:first-child {
 font-size: 27px;
}

.chartbox .MuiBox-root h6:nth-child(2),  .chartbox .MuiBox-root h6:nth-child(3) {
  display: flex;
  margin-top: -7px;
  font-size: 12px;
}

.chartbox .MuiBox-root h6:nth-child(2) {
  float: left;
}

.chartbox .MuiBox-root h6:nth-child(2)::after {
  content: '\/'
 }
 