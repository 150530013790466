@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #dadce0;
    font-family: 'Open Sans','Noto Sans KR', sans-serif;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width: 210mm;
    min-height: 297mm;
    padding: 5mm;
    margin: 10mm auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    page-break-before: always;
    background-color: #FFF;
}

.innerPage {
    padding: 5mm;
    background-color: transparent;
    height: 287mm;
    position: relative;
}
.page .innerPage {
    background: #FFF url(../img/bg.png) 0 0 no-repeat;
    background-size: contain;
    -webkit-print-color-adjust:exact;
}
.page.front .innerPage {
    background: #FFF url(../img/bg_front.png) 0 0 no-repeat;
    background-size: contain;
    -webkit-print-color-adjust:exact;
}
.page.front2 .innerPage {
    background: #FFF url(../img/bg_front2.png) 0 0 no-repeat;
    background-size: contain;
    -webkit-print-color-adjust:exact;
}
@page {
    size: A4;
    margin: 0;
}

@media print {

    html,
    body {
        width: 210mm;
        height: 297mm;
    }

    .page {
        margin: 0;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background-color: #FFF;
        page-break-after: always;
    }

    #printArea {
        zoom: 1.0 !important;
    }

    .no-print {
        display: none !important;
    }
    div:not(#printArea, #printArea div) {
        width: 210mm !important;
        height: 297mm !important;
        margin:0 !important;
        padding:0 !important;
    }
}

button {
    border: none;
    cursor: pointer;
}

.btnArea {
    position: fixed;
    bottom: 1%;
    right: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.btnArea>.btnPrint {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #80868b;
    margin: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}
.btnArea>.btnPrint:hover,
.btnArea>.btnPrint:focus,
.btnArea>.btnPrint:active {
    background-color: #6a7075;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.icoZoomIn{
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/ico_zoom_in.png) 50% 50% no-repeat;
    background-size: 20px;
}
.icoZoomOut{
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/ico_zoom_out.png) 50% 50% no-repeat;
    background-size: 20px;
}
.icoPrint{
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/ico_printer.png) 50% 50% no-repeat;
    background-size: 20px;
}
.icoQM{
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/ico_pdf.png) 50% 50% no-repeat;
    background-size: 16px;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.blocker {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    overflow: auto;
    z-index: 1;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(255,255,255,0.75);
    text-align: center;
    -webkit-print-color-adjust:exact;
  }
  .blocker:before{
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.05em;
  }
  .blocker.behind {
    background-color: transparent;
  }
  .modal {
    /* display: none; */
    vertical-align: middle;
    position: relative;
    z-index: 2;
    /* max-width: 375px; */
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    padding: 20px 30px 30px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.25);
    -o-box-shadow: 0 0 10px rgba(0,0,0,0.25);
    -ms-box-shadow: 0 0 10px rgba(0,0,0,0.25);
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    text-align: left;
}

.modal a.close-modal {
    position: absolute;
    top: -20px;
    right: -20px;
    display: block;
    width: 40px;
    height: 40px;
    text-indent: -9999em;
    border-radius: 100%;
    background: #80868b url(../img/ico_close.png) 50% 50% no-repeat;
    background-size: 14px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    -webkit-print-color-adjust:exact;
}
.modal a.close-modal:hover,
.modal a.close-modal:focus,
.modal a.close-modal:active {
    background-color: #6a7075;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    -webkit-print-color-adjust:exact;
}
.modal-spinner {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 12px 16px;
    border-radius: 5px;
    background-color: #111;
    height: 20px;
}

.modal-spinner > div {
    border-radius: 100px;
    background-color: #fff;
    height: 20px;
    width: 2px;
    margin: 0 1px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

.modal-spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.modal-spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.modal-spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.5) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.5);
        -webkit-transform: scaleY(0.5);
    }  20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
.modal>h2{
    font-size: 20px;
    margin: 0;
    font-weight: 600;
}
.modal>p{
    font-size: 14px;
    margin: 15px 0 0;
    word-break: keep-all;
}
.modal>p>strong{
    font-weight: 600;
    display: block;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 5px;
}
.modal>p>strong.chrome{
    background: url(../img/ico_chrome.png) 0 50% no-repeat;
    background-size: 20px;
    padding-left: 28px;
}
.modal>p>strong.edge{
    background: url(../img/ico_microsoft.png) 0 50% no-repeat;
    background-size: 20px;
    padding-left: 28px;
}
.modal>p>span{
    color: #0b57d0;
}
.section{
    position: relative;
    margin-bottom: 30px;
}
.section:last-child{
    margin-bottom: 0;
}

.section.wr60{
    width: 58%; 
    margin-left: 42%;
}
.logo{
    position: absolute;
    top: 158px;
    left: 78px;
    margin: 0;
    z-index: 2;
}
.logo>img{
    height: 36px;
}
.page h2{
    font-size: 14px;
    margin: 30px 0;
    font-weight: 400;
    color: #FFF;
    line-height: 1.25;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 10px;
}
.page.front h2,
.page.front2 h2{
    color: #262626;
    font-weight: 600;
}
.page h3{
    text-align: center;
    font-size: 30px;
    line-height: 1;
    margin: 0;
    padding: 30px 0;
    color: #FFF;
    font-weight: 600;
    position: relative;
}
.page.front h3,
.page.front2 h3{
    text-align: left;
    font-size: 40px;
    color: #5673e3;
    font-weight: 800;
}
h3>.koTitle{
    display: block;
    color: #464646;
    font-size: 30px;
    padding-top: 20px;
    font-weight: 800;
}
h3>.date{
    display: block;
    color: #464646;
    font-size: 15px;
    padding-top: 10px;
    font-weight: 600;
}
h4{
    margin: 0;
    margin-bottom: 5px;
    color: #262626;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h4>span {
    font-size: 13px;
    font-weight: 600;
    color: #262626;
}
.tableWrap{
    margin-bottom: 5px;
    background-color: transparent;
}
.tableWrap:last-child{
    margin-bottom: 0;
}
.table{
    border-spacing: 1;
    width: 100%;
}
.table th{
    background-color: #61c7c5;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    padding: 6px 5px;
    line-height: 1.25;
    -webkit-print-color-adjust:exact;
}
.table.type2 th{
    background-color: #ffb6a7;
    color: #464646;
    font-weight: 700;
    -webkit-print-color-adjust:exact;
}
.table.type3 th{
    background-color: #f4c771;
    color: #464646;
    font-weight: 700;
    -webkit-print-color-adjust:exact;
}
.table td{
    font-size: 12px;
    padding: 6px 5px;
    font-weight: 600;
    color: #262626;
    line-height: 1.25;
}
.table tr>td{
    background-color: #F2F2F2;
    -webkit-print-color-adjust:exact;
}
.table.type2 tr:nth-child(odd)>td,
.table.type3 tr:nth-child(odd)>td{
    background-color: #FFF;
}
.txtRight{
    text-align: right;
}
.txtCenter{
    text-align: center;
}
.subTxt{
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #262626;
}
.textType{
    height: 280px;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #FFF;
    line-height: 1.35;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content:flex-start;
}
.textType>p>span{
    display: block;
    color: #f4c771;
    font-weight: 700;
}
.whiteBox{
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 20px 30px 30px;
    background-color: #FFF;
    border-radius: 30px;
    margin-bottom: 40px;
}
.whiteBox:last-child{
    margin-bottom: 0;
}
.chartArea{
    margin-top: 20px;
    width: 100%;
}
.dotB{
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #5673e3;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -2px;
    -webkit-print-color-adjust:exact;
}
.dotG{
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #60c6c4;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -2px;
    -webkit-print-color-adjust:exact;
}
.dec{
    margin: 0;
    font-size: 13px;
    word-break: keep-all;
    font-weight: 500;
    color: #464646;
}
.signArea{
    width: 200px;
    border-bottom: #464646 1px solid;
    line-height: 2.2;
    float: right;
    margin-right: 20px;
    padding-top: 92px;
}
.signArea>span{
    color: #262626;
    font-weight: 600;
    font-size: 14px;
}
.pageNum{
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 40px;
    height: 40px;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    text-align: center;
    line-height: 40px;
}